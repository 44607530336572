import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Welcome to CourtMasters Tennis Club
			</title>
			<meta name={"description"} content={"Where passion meets play - CourtMasters, your tennis haven"} />
			<meta property={"og:title"} content={"Home | Welcome to CourtMasters Tennis Club"} />
			<meta property={"og:description"} content={"Where passion meets play - CourtMasters, your tennis haven"} />
			<meta property={"og:image"} content={"https://starledo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://starledo.com/img/Sport-tennis-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://starledo.com/img/Sport-tennis-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://starledo.com/img/Sport-tennis-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			md-justify-content="center"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.1) 67.71%,rgba(4, 8, 12, 0.1) 100%),url(https://starledo.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			sm-padding="40px 0 40px 0"
			quarkly-title="Hero-24"
			padding="0px 0 0px 0"
			md-align-items="center"
		>
			<Override
				slot="SectionContent"
				max-width="none"
				width="100%"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				flex-wrap="wrap"
				justify-content="flex-end"
				align-items="flex-end"
			/>
			<Box
				display="flex"
				align-items="flex-start"
				justify-content="center"
				padding="36px 48px 56px 48px"
				background="linear-gradient(120.99deg, #04080C 0%, rgba(25, 30, 34, 0.7) 100%)"
				flex-direction="column"
				width="30%"
				lg-width="50%"
				md-width="60%"
				sm-width="90%"
			>
				<Text
					text-transform="uppercase"
					letter-spacing="2px"
					margin="0px 0px 13px 0px"
					color="--lightD2"
					font="--base"
					lg-width="100%"
					lg-text-align="center"
				>
					Tennis Club
				</Text>
				<Text
					lg-width="100%"
					lg-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="0px 0px 17px 0px"
					color="--light"
					font="--headline2"
				>
					CourtMasters
				</Text>
				<Text
					color="--lightD2"
					font="--base"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					At CourtMasters Tennis Club, we fuel your passion for tennis by offering an inviting atmosphere for players of all ages and skill levels.{" "}
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Discover the CourtMasters Experience
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Immerse yourself in the world of tennis with CourtMasters Tennis Club. Each court tells a story of challenge, improvement, and triumph. We take pride in providing excellent playing conditions and a caring atmosphere for all club members. Whether you're picking up a racket for the first time or are an experienced player, CourtMasters offers the perfect conditions for your tennis journey.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://starledo.com/img/2.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 100px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				as="h2"
				margin="8px 0px 32px"
				font="--headline2"
				color="--dark"
				width="100%"
			>
				Why Join Us?
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 0px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				md-margin="0px 0px 16px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				- Best Playing Surfaces: Play on meticulously maintained courts designed to provide challenges and enjoyment for players of all levels.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 16px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				- Vibrant Community: The heart of CourtMasters is our community. Participate in social tournaments, club events, and meet a diverse group of people who share your love for tennis.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				md-margin="0px 0px 32px 0px"
				sm-width="100%"
				sm-margin="0px 0px 16px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				- Personalized Coaching: Take advantage of one-on-one coaching sessions with our team of experienced professionals ready to elevate your game.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 32px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				- Focus on Fitness: Complement your tennis with our fitness rooms and programs aimed at improving your strength, agility, and overall health.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				justify-content="center"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				width="75%"
			>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						position="absolute"
						display="block"
						right={0}
						min-height="100%"
						bottom={0}
						src="https://starledo.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						left={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				justify-content="space-between"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					CourtMasters Tennis Club - Unleash Your Passion for the Game
				</Text>
				<Box
					height="243px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="243px"
					overflow-y="hidden"
					border-radius="100%"
				>
					<Image
						right={0}
						bottom={0}
						min-height="100%"
						src="https://starledo.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						left={0}
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});